import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M1245 3700 c-18 -7 -19 -8 -2 -15 9 -4 24 -4 33 -1 19 7 70 -7 132
-37 46 -22 73 -64 51 -78 -10 -6 -10 -11 0 -22 6 -9 8 -18 3 -21 -14 -8 -16
-915 -1 -932 6 -7 9 -21 5 -29 -3 -8 -1 -15 5 -15 7 0 10 167 9 503 -1 276 -1
511 -1 522 1 17 3 17 15 5 8 -8 27 -41 43 -73 16 -31 32 -55 35 -51 3 3 6 -1
5 -8 -1 -31 4 -49 11 -44 5 3 9 0 9 -7 0 -29 66 -186 76 -180 5 3 7 1 3 -5 -3
-6 2 -32 12 -58 11 -26 21 -43 24 -38 3 5 3 4 2 -3 -5 -21 17 -73 29 -66 6 3
7 1 3 -6 -4 -6 4 -35 17 -65 14 -30 28 -68 32 -85 4 -17 11 -31 17 -31 6 0 9
-3 6 -6 -6 -5 3 -30 40 -116 9 -21 19 -33 22 -28 4 6 7 -3 7 -19 0 -49 53
-155 66 -134 3 5 67 6 142 3 75 -3 141 -2 146 1 5 3 0 16 -11 29 -11 13 -18
30 -15 37 3 8 0 11 -6 8 -6 -4 -9 -1 -8 6 4 22 -12 69 -21 64 -5 -3 -12 0 -16
6 -5 8 -3 9 6 4 9 -6 12 -4 8 6 -32 80 -41 98 -48 94 -5 -3 -8 3 -8 12 3 30
-12 74 -22 68 -6 -3 -7 1 -4 9 3 9 1 16 -6 16 -6 0 -9 4 -6 9 10 15 -29 92
-40 81 -7 -7 -10 0 -11 18 -1 47 -3 53 -12 47 -6 -3 -8 6 -5 21 3 18 1 23 -7
18 -7 -4 -9 -3 -6 3 9 14 -12 75 -23 68 -5 -3 -12 0 -16 6 -4 8 -3 9 4 5 17
-10 15 10 -3 28 -9 8 -15 19 -14 23 4 21 -2 33 -13 27 -7 -5 -8 -3 -3 5 4 7
-1 22 -13 34 -11 12 -20 35 -20 52 0 16 -4 31 -10 33 -6 2 -8 10 -5 19 5 12 3
14 -7 8 -8 -5 -11 -4 -7 2 4 6 -5 37 -20 69 -28 61 -30 67 -32 85 0 6 -8 8
-17 5 -14 -5 -15 -4 -5 8 8 10 9 16 1 21 -6 3 -8 10 -5 15 2 4 -4 19 -15 32
-11 14 -16 28 -13 32 3 3 -70 7 -163 7 -93 1 -193 2 -223 4 -50 2 -52 1 -25
-11 37 -16 25 -18 -13 -2 -35 14 -72 17 -98 8z m539 -126 c3 -8 2 -12 -4 -9
-6 3 -10 10 -10 16 0 14 7 11 14 -7z m-217 -56 c-3 -8 -6 -5 -6 6 -1 11 2 17
5 13 3 -3 4 -12 1 -19z m266 -633 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16
15 13 8 -4z m287 -105 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10
6 0 11 -4 11 -10z"/>
<path d="M3443 3702 l-163 -2 -15 -30 c-9 -17 -14 -30 -11 -30 2 0 -18 -51
-46 -112 -27 -62 -50 -123 -50 -136 0 -13 -3 -20 -6 -17 -6 6 -52 -101 -52
-122 0 -7 -5 -13 -11 -13 -6 0 -8 -9 -4 -21 4 -13 3 -19 -2 -16 -11 7 -68
-128 -66 -156 1 -10 -3 -15 -9 -11 -6 4 -8 3 -5 -3 3 -5 -5 -28 -19 -52 -13
-24 -21 -47 -18 -53 3 -5 0 -15 -8 -21 -19 -15 -60 -116 -52 -128 3 -5 1 -9
-4 -9 -12 0 -34 -49 -27 -61 4 -5 2 -9 -4 -9 -13 0 -34 -67 -27 -85 3 -9 0
-12 -8 -9 -8 3 -16 -4 -19 -15 -8 -33 20 -33 34 1 7 16 12 36 12 44 0 8 5 14
10 14 5 0 6 7 3 16 -3 8 -2 13 3 10 4 -3 14 14 21 37 8 23 22 59 32 80 10 21
16 42 13 47 -3 6 -1 10 5 10 7 0 9 7 6 16 -3 8 -2 13 3 10 10 -7 42 79 33 88
-3 3 0 6 6 6 7 0 12 5 12 11 0 5 11 35 25 64 14 31 22 62 18 71 -4 12 -3 15 5
10 8 -5 10 -1 5 10 -4 12 -3 15 6 10 6 -4 10 -4 9 1 -1 4 3 17 9 29 7 11 14
32 17 47 2 14 12 36 21 50 8 13 18 35 20 47 2 12 9 27 14 34 6 7 13 31 17 54
3 23 10 39 14 37 13 -8 46 81 49 133 1 7 6 10 13 6 7 -5 9 -2 6 7 -3 8 1 20 8
27 16 15 15 59 14 -588 0 -245 3 -450 8 -457 8 -13 244 -24 235 -10 -2 4 1 7
6 7 6 0 11 -4 11 -10 0 -5 7 -7 15 -4 13 5 14 72 12 509 -1 304 2 501 7 497 5
-3 6 3 3 12 -8 19 27 86 44 86 6 0 8 3 6 8 -5 7 7 9 80 12 20 1 42 8 49 14 13
14 18 14 -313 8z"/>
<path d="M1910 3341 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M1870 2415 c0 -9 9 -15 25 -15 16 0 25 6 25 15 0 9 -9 15 -25 15 -16
0 -25 -6 -25 -15z"/>
<path d="M2510 2330 c0 -77 3 -100 13 -100 8 0 17 3 21 7 10 10 46 11 40 1 -7
-12 24 -10 47 3 12 6 18 17 15 25 -3 9 -1 12 4 9 19 -12 10 52 -11 79 -21 26
-53 34 -64 16 -13 -21 -25 -9 -25 25 0 28 -4 35 -20 35 -19 0 -20 -7 -20 -100z
m98 -26 c4 -36 -16 -50 -44 -30 -26 19 -11 68 19 64 16 -2 23 -11 25 -34z"/>
<path d="M1600 2326 c0 -96 0 -96 25 -96 24 0 25 2 26 68 l1 67 21 -65 c14
-41 28 -66 39 -68 18 -3 34 31 22 49 -4 8 -3 9 4 5 7 -4 13 7 18 29 11 60 20
47 16 -21 l-4 -66 28 4 29 3 0 90 0 90 -28 3 c-37 4 -55 -12 -63 -57 -11 -59
-17 -59 -34 -1 -16 55 -16 55 -58 58 l-42 3 0 -95z"/>
<path d="M2173 2370 c-15 -6 -23 -18 -23 -34 0 -29 26 -47 75 -49 23 -1 30 -5
24 -13 -6 -8 -4 -14 7 -18 19 -7 35 10 28 29 -6 14 -40 33 -68 37 -11 2 -21 9
-24 16 -3 11 6 12 42 7 54 -8 63 7 15 24 -37 13 -46 13 -76 1z"/>
<path d="M2824 2370 c-28 -11 -36 -25 -41 -66 -3 -26 2 -37 23 -53 37 -29 79
-27 112 7 l27 27 -24 3 c-23 4 -44 -14 -35 -29 2 -4 -8 -4 -23 0 -23 6 -29 14
-31 44 -4 45 15 56 52 32 14 -9 33 -15 42 -13 15 3 15 5 -2 25 -22 26 -69 37
-100 23z"/>
<path d="M3002 2368 c-23 -12 -42 -60 -34 -91 5 -20 51 -47 80 -47 35 0 72 38
72 73 0 38 -7 50 -37 66 -26 13 -51 13 -81 -1z m63 -63 c0 -34 -4 -40 -22 -43
-17 -3 -24 3 -28 25 -4 15 -8 34 -11 41 -2 7 0 10 5 7 5 -3 13 -1 16 5 4 6 14
10 23 8 13 -2 17 -14 17 -43z"/>
<path d="M3222 2364 c-12 -8 -22 -10 -22 -5 0 6 -9 11 -20 11 -18 0 -20 -7
-20 -70 0 -69 1 -70 26 -70 24 0 25 3 24 43 -3 63 -2 67 20 67 12 0 20 -7 21
-17 0 -10 1 -34 2 -53 2 -28 6 -35 22 -35 21 0 22 2 24 65 1 36 4 40 26 40 24
0 25 -3 25 -56 0 -52 1 -55 23 -52 46 6 29 148 -19 148 -8 0 -12 -4 -9 -10 3
-5 0 -7 -8 -4 -8 3 -18 1 -22 -5 -4 -8 -13 -6 -28 4 -27 19 -37 19 -65 -1z"/>
<path d="M1872 2303 c3 -62 5 -68 26 -71 21 -3 22 0 22 67 0 69 -1 71 -25 71
-26 0 -26 -1 -23 -67z"/>
<path d="M1979 2344 c25 -32 26 -47 5 -64 -8 -8 -20 -22 -25 -32 -8 -15 -6
-18 15 -18 15 0 27 7 31 19 9 31 34 34 47 6 11 -25 58 -37 58 -14 0 5 -4 7
-10 4 -5 -3 -7 0 -4 8 3 8 -2 20 -11 27 -20 17 -19 26 6 61 l20 29 -22 0 c-13
0 -34 -12 -47 -27 -13 -16 -21 -23 -18 -16 12 22 -6 43 -36 43 l-29 0 20 -26z"/>
<path d="M2320 2311 c0 -72 16 -91 63 -73 21 8 34 8 43 1 24 -19 34 1 34 67 0
57 -2 64 -20 64 -17 0 -20 -7 -20 -42 0 -51 -12 -73 -34 -65 -12 5 -16 20 -16
57 0 47 -2 50 -25 50 -24 0 -25 -3 -25 -59z"/>
<path d="M2690 2255 c0 -20 5 -25 25 -25 20 0 25 5 25 25 0 20 -5 25 -25 25
-20 0 -25 -5 -25 -25z"/>
<path d="M2149 2257 c15 -19 73 -32 98 -22 16 5 16 8 3 15 -8 4 -37 11 -65 16
-45 6 -49 6 -36 -9z"/>
<path d="M1465 2150 c3 -5 2 -10 -4 -10 -6 0 -9 -96 -10 -252 -1 -289 -1 -289
-15 -303 -47 -42 -60 -48 -113 -52 -138 -12 -81 -21 128 -21 124 0 232 4 240
9 9 6 1 9 -26 8 -93 -1 -161 25 -171 66 -3 14 -9 25 -13 25 -3 0 -5 121 -3
270 3 197 1 270 -8 270 -6 0 -8 -4 -5 -10z m80 -620 c3 -6 -1 -7 -9 -4 -18 7
-21 14 -7 14 6 0 13 -4 16 -10z"/>
<path d="M2110 2148 c0 -4 4 -8 9 -8 5 0 12 -7 15 -16 5 -14 4 -15 -8 -5 -13
11 -14 9 -6 -16 18 -61 32 -93 41 -93 4 0 6 -6 3 -13 -3 -8 2 -19 10 -26 8 -7
12 -20 9 -28 -4 -11 -3 -14 6 -9 8 5 11 3 10 -5 -5 -22 53 -159 64 -152 5 3 6
-3 3 -15 -4 -11 -2 -23 4 -27 5 -3 10 -15 10 -26 0 -10 4 -19 10 -19 5 0 6 -7
3 -17 -4 -9 -2 -14 3 -11 5 4 10 -4 10 -15 1 -12 18 -58 39 -102 34 -73 38
-78 51 -60 8 10 11 28 8 38 -4 14 -3 17 7 12 9 -6 11 -2 7 13 -4 12 -2 20 3
16 9 -5 13 9 10 35 -2 8 2 10 10 5 8 -5 10 -3 5 5 -5 7 -1 20 7 29 22 25 48
92 40 105 -4 6 -1 7 7 2 9 -5 10 -2 5 14 -3 11 -3 21 2 21 13 0 44 80 37 93
-4 7 -3 9 1 4 10 -9 35 46 35 76 0 11 3 17 7 14 9 -9 35 52 27 64 -3 5 -1 9 4
9 13 0 34 59 25 73 -3 6 -1 7 5 3 15 -9 15 4 2 24 -9 13 -11 13 -20 0 -5 -8
-10 -22 -10 -30 0 -8 -9 -33 -20 -55 -11 -22 -19 -47 -20 -57 0 -9 -3 -18 -7
-20 -5 -1 -16 -20 -25 -40 -12 -26 -23 -36 -37 -35 -14 1 -17 5 -11 16 7 14
-11 79 -30 106 -4 6 -10 23 -14 38 -4 15 -10 25 -15 23 -5 -3 -11 12 -15 34
-3 22 -11 40 -17 40 -5 0 -8 -3 -5 -8 2 -4 -12 -6 -32 -5 -117 8 -262 8 -262
1z m307 -140 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M3286 2153 c-3 -3 -5 -122 -6 -264 0 -153 -5 -270 -11 -287 -15 -40
-39 -62 -68 -62 -21 0 -23 -2 -11 -10 10 -7 1 -8 -31 -4 -26 4 -55 2 -64 -4
-24 -13 618 -15 660 -2 27 8 25 9 -20 9 -90 -1 -159 37 -161 89 0 12 -1 132
-2 267 -2 226 -3 245 -19 246 -15 0 -15 2 2 9 23 10 33 9 -137 14 -70 2 -129
2 -132 -1z m251 -565 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
